import { Text } from '@mantine/core'
import {
  IconCalendarTime,
  IconCheck,
  IconChecks,
  IconClock,
  IconFile,
  IconFileAlert,
  IconFileOff,
  IconMailbox,
  IconSend,
  IconX
} from '@tabler/icons'

import Base from './Base'

export const ENVELOPE_STATUS = {
  draft: { icon: <IconFile />, label: 'Rascunho', color: 'grey' },
  ready_to_send: { icon: <IconCheck />, label: 'Pronto para enviar', color: 'green' },
  sent: { icon: <IconSend />, label: 'Enviado', color: 'blue' },
  delivered: { icon: <IconMailbox />, label: 'Entregue', color: 'lightblue' },
  waiting_for_others: { icon: <IconClock />, label: 'Aguardando outros', color: 'orange' },
  declined: { icon: <IconFileOff />, label: 'Recusado', color: 'red' },
  completed: { icon: <IconChecks />, label: 'Concluído', color: 'green' },
  expired: { icon: <IconCalendarTime />, label: 'Expirado', color: 'yellow' },
  failed: { icon: <IconFileAlert />, label: 'Falhou', color: 'orange' },
  canceled: { icon: <IconX />, label: 'Cancelado', color: 'red' }
}

type EnvelopeKeyType = keyof typeof ENVELOPE_STATUS

interface SingleProps {
  status: EnvelopeKeyType
}

function Envelope({ status, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status}
      color={ENVELOPE_STATUS[status]?.color}
      icon={ENVELOPE_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={ENVELOPE_STATUS[status]?.color} weight="normal">
        {ENVELOPE_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default Envelope
