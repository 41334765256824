import { Badge, Text } from '@mantine/core'
import { IconCalendarTime, IconChecks, IconClock, IconSend, IconX } from '@tabler/icons'
import React from 'react'

import Base from './Base'

export const PAYMENT_STATUS = {
  pending: { icon: <IconClock />, label: 'Pendente', color: 'orange' },
  processed: { icon: <IconCalendarTime />, label: 'Rascunho', color: 'grey' },
  sent: { icon: <IconSend />, label: 'Enviado', color: 'blue' },
  paid: { icon: <IconChecks />, label: 'Pago', color: 'green' },
  cancelled: { icon: <IconX />, label: 'Cancelado', color: 'red' }
}

type InstitutionContractPaymentKeyType = keyof typeof PAYMENT_STATUS

interface SingleProps {
  children?: React.ReactNode
  displayBadge?: boolean
  isOverdue?: boolean
  status: InstitutionContractPaymentKeyType
}

function InstitutionContractPayment({
  children,
  status,
  displayBadge = false,
  isOverdue = false,
  ...otherProps
}: SingleProps) {
  if (!status) return null
  if (status !== 'paid' && isOverdue) {
    return (
      <Badge variant="filled" color="red">
      Vencido
      </Badge>
    )
  }

  if (displayBadge) {
    return (
      <Badge
        id={status}
        variant="filled"
        color={PAYMENT_STATUS[status]?.color}
        {...otherProps}>
        {PAYMENT_STATUS[status]?.label}
        {children}
      </Badge>
    )
  }

  return (
    <Base
      id={status}
      color={PAYMENT_STATUS[status]?.color}
      icon={PAYMENT_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={PAYMENT_STATUS[status]?.color} weight="normal">
        {PAYMENT_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default InstitutionContractPayment
