import { Badge, Text } from '@mantine/core'
import { IconCheck, IconChecks, IconClock, IconX } from '@tabler/icons'
import React from 'react'

import Base from './Base'

export const PAYMENT_STATUS = {
  pending: { icon: <IconClock />, label: 'Pendente', color: 'orange' },
  active: { icon: <IconCheck />, label: 'Ativo', color: 'green' },
  completed: { icon: <IconChecks />, label: 'Finalizado', color: 'blue' },
  cancelled: { icon: <IconX />, label: 'Cancelado', color: 'red' }
}

type InstitutionContractPaymentKeyType = keyof typeof PAYMENT_STATUS

interface SingleProps {
  children?: React.ReactNode
  displayBadge?: boolean
  isOverdue?: boolean
  status: InstitutionContractPaymentKeyType
}

function InstitutionStudentEnrollment({
  children,
  status,
  displayBadge = false,
  isOverdue = false,
  ...otherProps
}: SingleProps) {
  if (!status) return null

  if (displayBadge) {
    return (
      <Badge id={status} variant="filled" color={PAYMENT_STATUS[status]?.color} {...otherProps}>
        {PAYMENT_STATUS[status]?.label}
        {children}
      </Badge>
    )
  }

  return (
    <Base
      id={status}
      color={PAYMENT_STATUS[status]?.color}
      icon={PAYMENT_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={PAYMENT_STATUS[status]?.color} weight="normal">
        {PAYMENT_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default InstitutionStudentEnrollment
