import { Text } from '@mantine/core'
import { IconCalendarTime, IconCheck, IconClock, IconFile, IconX } from '@tabler/icons'

import Base from './Base'

export const FORM_RESPONSE_STATUS = {
  draft: { icon: <IconFile />, label: 'Rascunho', color: 'grey' },
  pending: { icon: <IconClock />, label: 'Pendente', color: 'yellow' },
  submitted: { icon: <IconCheck />, label: 'Enviado', color: 'blue' },
  expired: { icon: <IconCalendarTime />, label: 'Expirado', color: 'orange' },
  canceled: { icon: <IconX />, label: 'Cancelado', color: 'red' }
}

type FormResponseKeyType = keyof typeof FORM_RESPONSE_STATUS

interface SingleProps {
  status: FormResponseKeyType
}

function FormResponse({ status, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status}
      color={FORM_RESPONSE_STATUS[status]?.color}
      icon={FORM_RESPONSE_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={FORM_RESPONSE_STATUS[status]?.color} weight="normal">
        {FORM_RESPONSE_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default FormResponse
