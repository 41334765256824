import { Badge, UnstyledButton } from '@mantine/core'
import { IconCheck, IconFile, IconX } from '@tabler/icons'
import React from 'react'

import { FormField as FormFieldType } from '@/types'

import { DateField } from '../DisplayData'

interface Props {
  children?: React.ReactNode
  field: FormFieldType
}

export default function Display({ children, field }: Props) {
  if (children) return children

  const { type, value } = field

  if (!value || value === null) return <Badge color="red">Não Preenchido</Badge>

  switch (type) {
    case 'str':
    case 'email':
    case 'phone':
    case 'cpf':
      return value
    case 'int':
    case 'float':
      return Number(value)
    case 'bool':
      return value ? <IconCheck /> : <IconX />
    case 'date':
      return <DateField value={value} type="date" />
    case 'datetime':
      return <DateField value={value} type="datetime" />
    case 'file':
      return (
        <UnstyledButton component="a" href={value} target="_blank" rel="noreferrer noopener">
          <Badge color="custom" leftSection={<IconFile size={10} />}>
            {value?.match?.(/([^/])*(?=\?)/)?.[0]}
          </Badge>
        </UnstyledButton>
      )
    case 'select':
      return value?.name
    case 'multi_select':
      return value
        ?.flatMap?.((item: any) => (typeof item === 'string' ? item : item.name))
        .join(', ')
    case 'address':
      return [
        value?.line1,
        value?.line4,
        value?.line2,
        value?.line3,
        value?.postcode,
        `${value?.city}/${value?.stateCode}${value?.countryCode ? ` - ${value.countryCode}` : ''}`
      ]
        .filter(item => item)
        .join(', ')
    default:
      return children
  }
}
