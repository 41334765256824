import { Text } from '@mantine/core'
import { IconChecks, IconFileOff, IconHourglass } from '@tabler/icons'

import Base from './Base'

export const SIGN_STATUS = {
  pending: { icon: <IconHourglass />, label: 'Pendente', color: 'orange' },
  signed: { icon: <IconChecks />, label: 'Assinado', color: 'green' },
  declined: { icon: <IconFileOff />, label: 'Recusado', color: 'red' }
}

type EnvelopeKeyType = keyof typeof SIGN_STATUS

interface SingleProps {
  status: EnvelopeKeyType
}

function Envelope({ status, ...otherProps }: SingleProps) {
  if (!status) return null

  return (
    <Base
      id={status}
      color={SIGN_STATUS[status]?.color}
      icon={SIGN_STATUS[status]?.icon}
      isReverted={false}
      {...otherProps}>
      <Text color={SIGN_STATUS[status]?.color} weight="normal">
        {SIGN_STATUS[status]?.label}
      </Text>
    </Base>
  )
}

export default Envelope
